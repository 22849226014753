import PropTypes from 'prop-types'
import React from 'react'
import profile from '../images/profile.jpg'
import grad from '../images/grad.jpg'
import cad from '../images/cad.png'
import work from '../images/work.png'

class Main extends React.Component {
  render() {
    let close = (
      <div 
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={grad} alt="" />
          </span>
          <p>
          <span className="image left">
            <img src={profile} alt="" />
          </span>
            Hi! My name is Emily and I'm currently in my second year of undergraduate studies at 
            the University of Waterloo. Studying Biomedical Engineering has been a fascinating 
            combination of my two interests: the incredible complexity that is the human body and 
            the captivating world of physics and mathematics. Through my studies, I have had the 
            unique opportunity to explore my interests in a multidisciplinary program with breadth 
            spanning software, mechanical and electrical engineering concepts observed in biomedical 
            applications.
          </p>

          <p>
            From starting my own company to travelling to Shanghai and studying at the University 
            of Traditional Chinese Medicine, I have always loved adventure.
            When I'm not studying for an exam or trying to get my code to run, you can find
            me playing on the basketball or volleyball court, trying to find cheap eats in Vancouver, 
            or getting sushi and bubble tea with friends! At Waterloo, I have been involved in various activities 
            including the BioMechatronics Design team, Engineers Without Borders, Engineering Ambassadors,
            and archery. I'm also passionate about creating social impact through meaningful
            initiatives and love collaborating with organizations whose values I resonate with. 
            Feel free to send me a message if we share any of the same interests, you'd like to collaborate, or 
            discuss why cilantro really shouldn't go on anything :)
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={work} alt="" />
          </span>
          <p>
            Through the co-op program at Waterloo, I have the unique opportunity 
            to apply what I've learned in the classroom and gain relevant skills and industry experience. 
            During my previous co-op term, I worked at Niagara Health in St. Catharine's, Ontario
            as a Biomedical Engineering Associate. In this position, I designed and 3D-printed
            components of medical equipment, assistive patient care devices, and anatomical models
            using SolidWorks, 3DSlicer and MeshMixer! 
          </p>
          
          <p>
            I'm currently working as a Chatbot Developer at I Ally Inc. which aims to support
            millenial caregivers without access to sufficient resources. Check out the platform 
            <a ref= "http://i-ally.com/"> here!</a>
          </p> 

          <p>
             In my spare time, I'm actively involved in a few organizations including
             AI for Anyone, Athena Pathways, and Junior Achievement. I also work part 
             time teaching STEM subjects to high school students as a tutor-mentor at TutorBright.
             Next summer, I will be supporting first year engineering students as a Women in Engineering
             Peer Leader at St. Paul's University College.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <span className="image fit">
            <img src={cad} alt="" />
          </span>
          
          <p>
            <b>Current Projects</b>
            <ul>
            <li>Machine learning application in healthcare to come. Stay tuned!</li>
            <li>Teaching myself to program an unbeatable tic tac toe AI bot</li>
            </ul>
            
            <b>Past Projects</b>
            <ul>
            <li>Assistive Laundry Folder: designed for individuals with a spinal cord injury</li>
            <li>rFan: an RFID blocking smart wallet designed for users with motor disorders</li>
            <li>FloaTent: a compact, inflatable shelter for use in the event of a flood</li>
            <li>CAD Design: modeled an exact replica of the Shanghai Pearl Tower, and Mater the
                tow truck from the Cars franchise </li>
            <li>3D Printing: designed a 3D puzzle of the whitefish</li>
            <li>Manufacturing: built a toy truck using machine shop tools (lathe, milling machine, drill press)</li>
            </ul>
          </p>
          <p>
              Feel free to reach out if you'd like to learn more about the projects I've done
              or want to collaborate!
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          *Resume can be shared upon request <br></br><br></br>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/e-yang/"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://instagram.com/emily.yaang" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/eyang10"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
